const APIKEY = "fca6edeabaa562c22483a395bc07a54d";

const requests ={
    fetchTreading:`/trending/all/week?api_key=${APIKEY}&page=1`,
    fetchNetflixOriginal:`/discover/tv?api_key=${APIKEY}&with_network=213`,
    fetchTopRated:`/movie/top_rated?api_key=${APIKEY}&language=en-US&page=1`,
    fetchActionMovies:`/discover/movie?api_key=${APIKEY}&with_genres=28`,
    fetchComdeyMovies:`/discover/movie?api_key=${APIKEY}&with_genres=35`,
    fetchHorroMovies:`/discover/movie?api_key=${APIKEY}&with_genres=27`,
    fetchRomanceMovies:`/discover/movie?api_key=${APIKEY}&with_genres=10749`,
    fetchDocumentaries:`/discover/movie?api_key=${APIKEY}&with_genres=99`,
}

export default requests;