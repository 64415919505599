import React, {useState,useEffect} from 'react'
import axios from './axios';
import './Row.css'
const base_url ="http://image.tmdb.org/t/p/original"

export default function Row({title,fetchUrl,isLargeRow}) {
    const [movies,SetMovies] = useState([])
    const [trailerUrl,setTrailerUrl] = useState("")
    useEffect(() => {
       async function functionfetchData(){
        const request = await axios.get(fetchUrl);
        SetMovies(request.data.results);
       }
       functionfetchData();
    }, [fetchUrl]) 

    return (
        <div className="row">
            <h2>{title}</h2>
            <div className="row_posters">
                {
                    movies.map(movies=>(
                        <img 
                        className={`row_poster ${isLargeRow &&"row_posterLarge"}`}
                        key={movies.id}
                        src={`${base_url}${isLargeRow ? movies.poster_path : movies.backdrop_path}`} 
                        alt={movies.name}>
                        </img>
                    ))
                }
            </div>
        </div>
    )
}
