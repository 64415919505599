import React from 'react';
import './App.css';
import Row from './Row';
import requests from './request';
import Navbar from './Navbar';
import Banner from './Banner';



function App() {
  return (
    <div className="App">
      <Navbar>

      </Navbar>
      <Banner>
        
      </Banner>
      
      <Row 
      title={'NETFLIX ORIGINAL'} 
      fetchUrl={requests.fetchNetflixOriginal}
      isLargeRow={true}
      ></Row>
      <Row 
      title={'Trending Now'} 
      fetchUrl={requests.fetchTreading}
      isLargeRow={false}
      ></Row>
      <Row 
      title={'Top Rated'} 
      fetchUrl={requests.fetchTopRated}
      isLargeRow={false}
      ></Row>
      <Row 
      title={'Action Movies'} 
      fetchUrl={requests.fetchActionMovies}
      isLargeRow={false}
      ></Row>
      <Row 
      title={'Comdey Movies'} 
      fetchUrl={requests.fetchComdeyMovies}
      isLargeRow={false}
      ></Row>
      <Row 
      title={'Horro Movies'} 
      fetchUrl={requests.fetchHorroMovies}
      isLargeRow={false}
      ></Row>
      <Row 
      title={'Romance Movies'} 
      fetchUrl={requests.fetchRomanceMovies}
      isLargeRow={false}
      ></Row>
      <Row 
      title={'Documentaries'} 
      fetchUrl={requests.fetchDocumentaries}
      isLargeRow={false}
      ></Row>
    </div>
  );
}

export default App;
